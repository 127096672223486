import React from 'react';
import Tooltip from 'react-tooltip';

import { ReactComponent as CrossIcon } from 'images/cross-icon.svg';
import { ReactComponent as CircularCheckIcon } from 'images/circular-check-icon.svg';
import { ReactComponent as QuestionRoundedIcon } from 'images/question-rounded-icon.svg';

import styles from './CardComparisonTable.module.scss';

interface DataComparisonTable {
  labelTd: React.ReactNode | string;
  debitTd: React.ReactNode;
  creditTd: React.ReactNode;
  planneryTd: React.ReactNode | string;
}

const dataComparisonTable: DataComparisonTable[] = [
  {
    labelTd: (
      <>
        Rewards
        <QuestionRoundedIcon data-for="rewards-tooltip" data-tip="" className={styles.infoTooltipIcon} />
      </>
    ),
    debitTd: <CrossIcon className={styles.grayCrossIcon} />,
    creditTd: 'Over-spending',
    planneryTd: 'Healthy Spending',
  },
  { labelTd: 'APR', debitTd: <CrossIcon className={styles.grayCrossIcon} />, creditTd: '30.32%', planneryTd: '14.93%' },
  {
    labelTd: 'Avoid Debt',
    debitTd: <CircularCheckIcon />,
    creditTd: <CrossIcon className={styles.grayCrossIcon} />,
    planneryTd: <CircularCheckIcon className={styles.purpleCheckIcon} />,
  },
  {
    labelTd: 'Build Credit',
    debitTd: <CrossIcon className={styles.grayCrossIcon} />,
    creditTd: <CircularCheckIcon />,
    planneryTd: <CircularCheckIcon className={styles.purpleCheckIcon} />,
  },
  {
    labelTd: (
      <>
        Guidance
        <QuestionRoundedIcon data-for="guidance-tooltip" data-tip="" className={styles.infoTooltipIcon} />
      </>
    ),
    debitTd: <CrossIcon className={styles.grayCrossIcon} />,
    creditTd: <CrossIcon className={styles.grayCrossIcon} />,
    planneryTd: <CircularCheckIcon className={styles.purpleCheckIcon} />,
  },
];

const CardComparisonTable = () => (
  <table className={styles.comparisonTable}>
    <Tooltip id="rewards-tooltip" place="right" className={styles.tooltipContainer}>
      <span>Overspending: </span>
      Traditional credit cards often reward you more as you spend more, which can encourage overspending.
      <br />
      <span>Healthy Spending: </span>
      The Plannery Card rewards you for staying within your budget. You’ll set a personalized budget with Plannery and
      earn rewards for healthy financial habits.
    </Tooltip>
    <Tooltip id="guidance-tooltip" place="right" className={styles.tooltipContainer}>
      Get AI-driven insights and financial coaching to help you manage your budget and avoid overspending.
    </Tooltip>
    <thead>
      <tr>
        <th> </th>
        <th className={styles.thWithRadius}>Debit</th>
        <th>Credit</th>
        <th>Plannery</th>
      </tr>
    </thead>
    <tbody>
      {dataComparisonTable.map((row, index) => (
        <tr key={index}>
          <td>{row.labelTd}</td>
          <td>{row.debitTd}</td>
          <td>{row.creditTd}</td>
          <td className={typeof row.planneryTd === 'string' ? styles.highlightText : ''}>{row.planneryTd}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CardComparisonTable;
