import React from 'react';

import { FlowComponentType } from 'routes/types';

import Button, { ButtonType } from 'components/Button';
import FormNavigation from 'components/FormNavigation/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import { ApplyEmployeeLoanResult } from 'enums/FlowNextResults';

import { ReactComponent as TitleIcon } from 'images/offer-available-icon.svg';

import styles from './ApplyEmployeeLoan.module.scss';

const EmployeeLoan = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer
      icon={<TitleIcon />}
      title={
        <>
          While you wait, want to access up to a <span className={styles.titleHighlight}>$15,000</span> Healthcare
          Employee Loan?
        </>
      }
      subtitle="Access below market interest rates exclusively for healthcare professionals."
    >
      <div className={styles.disclaimerContainer}>
        By clicking Apply, you’ll begin a loan application. See{' '}
        <a href="https://www.planneryapp.com/lending/nurses" target="_blank" rel="noreferrer">
          Product Details
        </a>{' '}
        to learn more.
      </div>
      <div className={styles.buttonsContainer}>
        <Button onClick={() => handleNext(ApplyEmployeeLoanResult.Apply)}>Apply</Button>
        {/* // TODO: redirect to your savings? */}
        <Button type={ButtonType.Inverted} onClick={handleNext}>
          No Thanks
        </Button>
      </div>
    </FormContainer>
  </>
);

export default EmployeeLoan;
