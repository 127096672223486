import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import MainLayout from 'components/MainLayout/MainLayout';
import { getSelectedTheme } from 'selectors/theme';
import { RoutePath } from 'enums/Routes';

import ThemeContext from './ThemeContext';

const renderConfettiBackground = (path: string) => {
  switch (path) {
    case RoutePath.ConfirmLoan:
    case RoutePath.OfferAvailable:
    case RoutePath.StudentLoanSavings:
    case RoutePath.CardApplicationSuccessful:
    case RoutePath.CardSummary:
    case RoutePath.ApplyEmployeeLoan:
      return true;
    default:
      return false;
  }
};

const EntryPoint = () => {
  const location = useLocation();
  const { selectedTheme } = useSelector(getSelectedTheme);
  const [theme, setTheme] = useState(selectedTheme);
  const value = { theme, setTheme };

  return (
    <ThemeContext.Provider value={value}>
      <MainLayout confettiBackground={renderConfettiBackground(location.pathname)}>
        <Outlet />
      </MainLayout>
    </ThemeContext.Provider>
  );
};

export default EntryPoint;
