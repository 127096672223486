import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversionType, trackConversion } from 'utils/analytics';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { applyForCard, applyForCardWithApplication, getCardDataForLoanApplication } from 'thunks';
import { CardApplyResponse, GetCardApplicationResponse } from 'api/CardApi';
import { ProfessionGroup } from 'enums/ProfessionGroup';

import { DebtSummary } from './applicationData';

export enum CurrentOnboardingSteps {
  Rewards = 0,
  SmartSpending = 1,
  SmartAutoPay = 2,
  ImportDebt = 3,
  Completed = 4,
}

export enum CardDataVariable {
  Credentials = 'credentials',
}

export enum ReferralProgramID {
  /**
   * Don't offer anything.
   */
  NoRewards = 'noRewards',

  /**
   * Shows launch progress, no reward.
   */
  LaunchProgress = 'launchProgress',

  /**
   * Move higher up the waitlist for each referral.
   */
  Waitlist = 'waitlist',

  /**
   * Free pair of Gales, worth up to $120.
   */
  Gales = 'gales',

  /**
   * Cash back, up to $25 per redemption.
   */
  CashBack25 = 'cashBack25',
}

export enum ReferralProgramReward {
  /**
   * Move higher up the waitlist for each referral.
   */
  MoveUpWaitlist = 'MoveUpWaitlist',

  /**
   * Free pair of Gales, worth up to $120.
   */
  FreeGales = 'FreeGales',

  /**
   * Cash back, up to $25.
   */
  CashBack25 = 'CashBack25',
}

export interface ReferralProgram {
  id: ReferralProgramID;
  credits: number;
  rewards: RewardStatus[];
  referredApplicants: ReferralData[];
}

export interface BaseRewardDefinition {
  id: ReferralProgramReward;
  maxRedemption: number;
  manualRedemption: boolean;
  timesRedeemed: number;
  weightPoints: number;
}

interface CreditsBasedReward extends BaseRewardDefinition {
  readonly creditsToRedeem: number;
  readonly referralsToRedeem?: never;
}

interface ReferralsBasedReward extends BaseRewardDefinition {
  readonly referralsToRedeem: number;
  readonly creditsToRedeem?: never;
}

type RewardDefinition = CreditsBasedReward | ReferralsBasedReward;
export type RewardStatus = RewardDefinition & { timesRedeemed: number };

export interface CardDataFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
}

export interface ReferralData {
  firstName: string;
  lastName: string;
  httpReferrer: string;
}

export interface CardData {
  applicationId?: string;
  applied?: boolean;
  referredBy?: string;
  referralLink?: string;
  isQualified?: boolean;
  hasAlreadyApplied?: boolean;
  cardColor?: CardColor;
  debtSummary?: DebtSummary;
  debtConsolidationPossible?: boolean;
  waitListPosition?: number;
  originalWaitListPosition?: number;
  borrowerCredentials?: string;
  initialReferrer?: string;
  totalDebt?: number | null;
  referralProgram?: ReferralProgram;
  waitListLength?: number;
  totalAnnualIncome?: number;
  professionGroup?: ProfessionGroup;
  creditScore?: number;
}

interface OnboardingFlow {
  currentOnboardingStep: CurrentOnboardingSteps;
  onboardingCompleted: boolean;
}

type CardDataState = CardData & CardDataFetchStatus & OnboardingFlow;

const initialState: CardDataState = {
  applied: false,
  isLoading: false,
  error: false,
  fetched: false,
  referralProgram: {
    id: ReferralProgramID.NoRewards,
    credits: 0,
    rewards: [],
    referredApplicants: [],
  },
  currentOnboardingStep: CurrentOnboardingSteps.Rewards,
  onboardingCompleted: false,
};

const cardData = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setCardData: (state: CardData, { payload }: PayloadAction<CardData>) => {
      if (payload.applied && !payload.hasAlreadyApplied) {
        trackConversion(ConversionType.CardApplied);
      }
      payload.applicationId && (state.applicationId = payload.applicationId);
      payload.applied !== undefined && (state.applied = payload.applied);
      payload.referredBy && (state.referredBy = payload.referredBy);
      payload.referralLink && (state.referralLink = payload.referralLink);
      payload.cardColor && (state.cardColor = payload.cardColor);
      state.debtSummary = payload.debtSummary;
      state.debtConsolidationPossible = payload.debtConsolidationPossible;
      payload.waitListPosition && (state.waitListPosition = payload.waitListPosition);
      payload.originalWaitListPosition && (state.originalWaitListPosition = payload.originalWaitListPosition);
      payload.initialReferrer && (state.initialReferrer = payload.initialReferrer);
      payload.borrowerCredentials && (state.borrowerCredentials = payload.borrowerCredentials);
      payload.totalDebt !== undefined && (state.totalDebt = payload.totalDebt);
      payload.referralProgram && (state.referralProgram = payload.referralProgram);
      payload.waitListLength && (state.waitListLength = payload.waitListLength);
      payload.totalAnnualIncome && (state.totalAnnualIncome = payload.totalAnnualIncome);
      payload.professionGroup && (state.professionGroup = payload.professionGroup);
      payload.creditScore && (state.creditScore = payload.creditScore);
    },
    setCurrentOnboardingStep: (state: CardDataState, { payload }: PayloadAction<CurrentOnboardingSteps>) => {
      state.currentOnboardingStep = payload;
      if (payload === CurrentOnboardingSteps.ImportDebt) {
        state.onboardingCompleted = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCardDataForLoanApplication.pending, (state) => {
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(
      getCardDataForLoanApplication.fulfilled,
      (state, { payload }: PayloadAction<GetCardApplicationResponse>) => {
        state.isLoading = false;
        state.fetched = true;

        const { application, waitListLength } = payload;

        state.applicationId = application.id;
        state.cardColor = application.cardColor;
        state.waitListPosition = application.cardWaitListPosition;
        state.originalWaitListPosition = application.cardOriginalWaitListPosition;
        application.referralProgram && (state.referralProgram = application.referralProgram);
        application.cardReferralLink && (state.referralLink = application.cardReferralLink);
        state.borrowerCredentials = application.borrowerCredentials;
        state.waitListLength = waitListLength;
      },
    );
    builder.addCase(getCardDataForLoanApplication.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
    });
    builder.addCase(applyForCard.fulfilled, (state, { payload }: PayloadAction<CardApplyResponse>) => {
      payload.waitListPosition && (state.waitListPosition = payload.waitListPosition);
      payload.originalWaitListPosition && (state.originalWaitListPosition = payload.originalWaitListPosition);
    });
    builder.addCase(applyForCardWithApplication.fulfilled, (state, { payload }: PayloadAction<CardApplyResponse>) => {
      payload.waitListPosition && (state.waitListPosition = payload.waitListPosition);
      payload.originalWaitListPosition && (state.originalWaitListPosition = payload.originalWaitListPosition);
    });
  },
});

export const { setCardData, setCurrentOnboardingStep } = cardData.actions;

export default cardData.reducer;
