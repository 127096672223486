import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import { getCardData } from 'selectors/getCardData';
import { CurrentOnboardingSteps, setCurrentOnboardingStep } from 'handlers/cardData';

import styles from './Stepper.module.scss';

interface StepData {
  id: CurrentOnboardingSteps;
  name: string;
  route: RoutePath;
}

const stepList: StepData[] = [
  { id: CurrentOnboardingSteps.Rewards, name: 'Rewards', route: RoutePath.CardRewards },
  { id: CurrentOnboardingSteps.SmartSpending, name: 'Smart Spending', route: RoutePath.SmartSpending },
  { id: CurrentOnboardingSteps.SmartAutoPay, name: 'Smart Auto Pay', route: RoutePath.SmartAutoPay },
  { id: CurrentOnboardingSteps.ImportDebt, name: 'Import Debt', route: RoutePath.ImportDebt },
];

const Stepper = ({ currentStep }: { currentStep: number }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onboardingCompleted } = useSelector(getCardData);

  const handleOnClick = (activeStep: boolean, step: StepData) => {
    if (activeStep || onboardingCompleted) {
      dispatch(setCurrentOnboardingStep(step.id));
      navigate(step.route);
    }
  };

  return (
    <div className={styles.mainContainer}>
      {stepList.map((step, index) => (
        <div
          key={index}
          className={clsx(
            styles.stepContainer,
            index <= currentStep && styles.activeStepText,
            index >= currentStep && styles.disabled,
          )}
          onClick={() => handleOnClick(index < currentStep, step)}
        >
          <span>{step.name}</span>
          <div className={clsx(styles.stepIndicator, index <= currentStep && styles.activeStepBar)} />
        </div>
      ))}
    </div>
  );
};

export default Stepper;
