import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { PartnerId } from 'enums/PartnerName';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg';
import LinkButton from 'components/LinkButton';
import { PhoneNumbers, PhoneNumbersLabels } from 'enums/PhoneNumbers';
import { getPaymentAccountData } from 'selectors/paymentAccount';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './Footer.module.scss';

const manualSetupDeductionRoutes = [
  RoutePath.SetupDeduction,
  RoutePath.SetupViaHr,
  RoutePath.SetupViaPayroll,
] as string[];

const studentLoanSupportRoutePrefix = '/student-loan';

const Footer = () => {
  const location = useLocation();
  const { themeStyles } = useLayoutTheme();
  const { partnerId: partnerName } = useSelector((state: RootState) => state.preQualificationData);
  const { optedOut } = useSelector(getPaymentAccountData);
  const showUsPatriotActDisclosure =
    location.pathname === RoutePath.YourName || location.pathname === RoutePath.ConfirmYourDetails;

  const { phoneNumber } = getPhoneNumberInfo(location.pathname as RoutePath);

  const openSmsApp = () => {
    const smsAppUrl = `sms://${phoneNumber}`;
    window.open(smsAppUrl);
  };

  return (
    <div className={styles.footer}>
      {showUsPatriotActDisclosure && (
        <span className={styles.disclaimerText}>
          <p>
            <b>US Patriot Act Disclosure</b>
          </p>
          <p>
            To help the government fight the funding of terrorism and money laundering activities, federal law requires
            all financial institutions to obtain, verify, and record information that identifies each person who applies
            for an account. What this means for you: When you open an account, we may ask for your name, address, date
            of birth, and other information that will allow us to identify you. We may also ask to see your driver's
            license or other identifying documents.
          </p>
        </span>
      )}

      <div className={styles.contactButton}>
        <ChatIcon className={clsx(styles.icon, themeStyles.chatIcon)} />
        <LinkButton className={themeStyles.needHelp} onClick={openSmsApp} isLoading={false}>
          Need Help? Contact us at {PhoneNumbersLabels.Support}
        </LinkButton>
      </div>

      {location.pathname === RoutePath.Offer && (
        <span className={styles.disclaimerText}>
          Market rate loan data is based our compilation of rates from specific aggregator websites, on specific dates.
          Only considers specific lenders who share pricing on these sites. Please do your own shopping to confirm rates
          you may qualify for outside of Plannery.
        </span>
      )}

      {manualSetupDeductionRoutes.includes(location.pathname) && !optedOut && (
        <div className={styles.disclaimerText}>Banking services are provided by Emprise Bank, Member FDIC.</div>
      )}
      {partnerName === PartnerId.Fpma && <span className={styles.podiatristsText}>Plannery ❤️ podiatrists</span>}
      <span className={clsx(styles.copyright, themeStyles.copyright)}>
        &#169; {new Date().getFullYear()} Plannery. All Rights Reserved
      </span>
    </div>
  );
};

const getPhoneNumberInfo = (
  routePath: RoutePath,
): { phoneNumber: PhoneNumbers; phoneNumberLabel: PhoneNumbersLabels } => {
  if (routePath.startsWith(studentLoanSupportRoutePrefix)) {
    return { phoneNumber: PhoneNumbers.StudentLoanSupport, phoneNumberLabel: PhoneNumbersLabels.StudentLoanSupport };
  }
  return { phoneNumber: PhoneNumbers.Support, phoneNumberLabel: PhoneNumbersLabels.Support };
};

export default Footer;
