import React, { useState } from 'react';

import Button, { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import AreYouSureModal, { NurseName } from 'components/ReferralProgram/AreYouSureModal';
import BenefitsList, { BenefitItemProps } from 'components/Card/BenefitsList';
import { FlowComponentType } from 'routes/types';

import { ReactComponent as CardImage } from 'images/cards/florence-gold-card-front.svg';
import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-card-benefit-icon.svg';
import { ReactComponent as TrophyIcon } from 'images/trophy-card-benefit-icon.svg';
import { ReactComponent as PercentageIcon } from 'images/percentage-card-benefit-icon.svg';

import styles from './Summary.module.scss';

const benefitsList: BenefitItemProps[] = [
  {
    icon: <TrophyIcon />,
    title: (
      <>
        <span>Earn up to $500</span> in rewards.
      </>
    ),
  },
  {
    icon: <CalendarIcon />,
    title: (
      <>
        <span>Spend less</span> and get AI coaching to keep you in budget.
      </>
    ),
  },
  {
    icon: <PercentageIcon />,
    title: (
      <>
        <span>Pay up to 75% less interest</span> compared to the average credit card.
      </>
    ),
  },
  {
    icon: <MoneyIcon />,
    title: (
      <>
        <span>Save up to $6,000</span> when you import your other balances.
      </>
    ),
  },
];

const Summary = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);

  const handleDeclineAreYouSureModal = () => {};

  const handleCloseAreYouSureModal = () => {
    setShowAreYouSureModal(!showAreYouSureModal);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Plannery Card Summary">
        <div className={styles.cardContainer}>
          <CardImage className={styles.cardImage} />
          <div className={styles.cardSummaryContainer}>
            <div>
              Total Credit
              <div>
                <span>$1,500-$15,000</span>¹
              </div>
            </div>
            <div>
              APR (if paid within 30 days)
              <span>0%</span>
            </div>
            <div>
              APR
              <span>12.99-23.99%</span>
            </div>
            <div>
              Membership fee
              <span>$7 biweekly</span>
            </div>
          </div>
        </div>

        <div className={styles.containerSavings}>
          <p>
            Total estimated savings: <span>$5,500</span>²
          </p>
          <div className={styles.spacingLine} />
          <BenefitsList className={styles.benefitsListContainer} benefits={benefitsList} />
        </div>

        {showAreYouSureModal && (
          <AreYouSureModal
            nurseName={NurseName.Jahira}
            closeModal={handleCloseAreYouSureModal}
            handleDecline={handleDeclineAreYouSureModal}
          />
        )}

        <div className={styles.buttonsContainer}>
          <Button type={ButtonType.Primary} onClick={handleNext}>
            Join Waitlist
          </Button>
          {/* TODO: Pending feedback about the correct CTA: */}
          <Button type={ButtonType.Transparent} onClick={handleCloseAreYouSureModal}>
            Not Now
          </Button>
        </div>
      </FormContainer>
      <div className={styles.disclaimerContainer}>
        <p>
          ¹ Total credit and APR are based on Plannery’s personal lending terms. Terms are subject to change and
          qualification.
        </p>
        <p>² Estimated savings is based on expected usage over 12 months.</p>
      </div>
    </>
  );
};

export default Summary;
