import React from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';

import nurseJasonImage from 'images/nurse-jason.png';
import Modal from 'components/Common/Modal';
import { FlowNextResult } from 'enums/FlowNextResults';

import { ReactComponent as NurseJahira } from 'images/nurse-jahira.svg';
import { ReactComponent as OpenQuotesIcon } from 'images/open-quotes-icon.svg';
import { ReactComponent as CloseQuotesIcon } from 'images/close-quotes-icon.svg';

import styles from './AreYouSureModal.module.scss';

export enum NurseName {
  Jason = 'Jason',
  Jahira = 'Jahira',
}

interface TestimonyProps {
  name: string;
  image: React.ReactNode | string;
  imageExtension: 'svg' | 'png';
  testimony: string;
  credentials: string;
}

interface NursesDataTestimonyProps {
  [NurseName.Jason]: TestimonyProps;
  [NurseName.Jahira]: TestimonyProps;
}

interface AreYouSureModalProps {
  closeModal: () => void;
  handleDecline: (result?: FlowNextResult) => void;
  nurseName?: NurseName;
}

const nursesDataTestimony: NursesDataTestimonyProps = {
  [NurseName.Jason]: {
    name: 'Jason',
    image: nurseJasonImage,
    imageExtension: 'png',
    testimony:
      "Plannery has been life changing. I'm so thankful to have a rising credit score, and a plan to be debt free in 3 years.",
    credentials: 'PA-C',
  },
  [NurseName.Jahira]: {
    name: 'Jahira',
    image: <NurseJahira />,
    imageExtension: 'svg',
    testimony:
      'The savings have been life-changing, and because of this financial relief, I feel much more secure and less stressed about my finances.',
    credentials: 'BSN, RN',
  },
};

const AreYouSureModal = ({ handleDecline, closeModal, nurseName }: AreYouSureModalProps) => {
  const nurseData = nursesDataTestimony[nurseName || NurseName.Jason];
  const isSvgImageExtension = nurseData.imageExtension === 'svg';

  return (
    <Modal closeModal={closeModal}>
      <>
        <p className={styles.title}>Are You Sure?</p>
        <div className={styles.mainContainer}>
          <p className={styles.subtitle}>Do your part and help people like {nurseData.name} find out about Plannery!</p>
          <div className={clsx(styles.card, isSvgImageExtension && styles.cardWithLessGap)}>
            {isSvgImageExtension ? (
              nurseData.image
            ) : (
              <img className={styles.image} src={nurseJasonImage} alt={`Nurse ${nurseData.name}`} />
            )}
            <div className={styles.testimonyContainer}>
              <OpenQuotesIcon className={styles.openQuotesIcon} />
              <p className={styles.testimony}>{nurseData.testimony}</p>
              <div className={styles.personalInfoContainer}>
                <p className={styles.name}>{nurseData.name}</p>
                <p className={styles.credentials}>{nurseData.credentials}</p>
                <CloseQuotesIcon className={styles.closeQuotesIcon} />
              </div>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <Button onClick={closeModal} className={styles.button}>
              Share Plannery
            </Button>
            <Button onClick={handleDecline} type={ButtonType.Inverted}>
              No Thanks
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default AreYouSureModal;
